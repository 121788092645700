import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import ContactForm from "../components/Contact/ContactForm";
import JoinCommunity from "../components/Home/JoinCommunity";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

const Contact = ({ location }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdEh7AUAAAAAO0ZtM7aTWZv6t8-e_dDDK_6Y64N">
      <Layout>
        <Helmet>
          <title>Contact Us | HydraChain</title>
          <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
        </Helmet>
        <Navbar />
        <ContactForm />
        <JoinCommunity />
        <Footer />
      </Layout>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
