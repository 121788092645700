import React, { useRef, useState } from "react";
import starIcon from "../../assets/images/star-icon.png";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { StaticImage } from "gatsby-plugin-image";

const ContactForm = () => {
  const url = "https://hydra.locktrip.com/contact/send";
  const formRef = useRef();
  const textAreaRef = useRef();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });
  };

  const sendFormData = async () => {
    try {
      setLoading(true);
      const token = await executeRecaptcha("contactpage");

      const response = await fetch(url, { method: "POST", body: JSON.stringify(formData), headers: { "Content-type": "application/json", captcha: token } });
      if (response.ok) {
        setFormData({});
        formRef.current.reset();
        textAreaRef.current.value = "";
        NotificationManager.success("Thank you for getting in touch with us. We will get back to you soon.", "Message Sent!");
      } else {
        NotificationManager.error("There was some problem sending your message. Please try again!", "Message Not Sent!");
      }
      setLoading(false);
    } catch (e) {
      NotificationManager.error("There was some problem sending your message. Please try again!", "Message Not Sent!");
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    sendFormData();
  };

  return (
    <section className="contact-area mt-5 pt-100 pb-100">
      <div className="container">
        <div className="section-title text-center">
          <span className="sub-title text-center">
            <img src={starIcon} style={{ width: 30 }} alt="contact" />
            Get in Touch
          </span>
          <h2>Have any queries?</h2>
          <p>You can get in touch with our team by sending us a message using the form below.</p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <StaticImage placeholder="blurred" src="../../assets/images/contact.png" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form ref={formRef} id="contactForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input disabled={loading} value={formData.name} required onChange={handleInputChange} type="text" name="name" className="form-control" placeholder="Your name" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input disabled={loading} value={formData.email} required onChange={handleInputChange} type="email" name="email" className="form-control" placeholder="Your email address" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input disabled={loading} value={formData.phone} onChange={handleInputChange} type="text" name="phone" className="form-control" placeholder="Your phone number" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea ref={textAreaRef} disabled={loading} value={formData.message} required onChange={handleInputChange} name="message" className="form-control" cols="30" rows="6" placeholder="Write your message..." />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <button disabled={loading} type="submit" className={`default-btn ${loading && "disabled"}`}>
                      {loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="flaticon-tick"></i>}
                      {loading ? "Sending..." : "Send Message"} <span></span>
                    </button>
                  </div>
                </div>
              </form>
              <NotificationContainer />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
