import { Link } from "gatsby";
import React from "react";
const JoinCommunity = () => {
  return (
    <section className="funfacts-area bg-image pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="single-funfacts-item text-center">
              <h3>Join our community</h3>
              <p className={"mt-3"}>
                We have a great community on Twitter and Telegram.
                <br />
                Join us there and engage with other network participants.
              </p>
              <ul className="list-inline text-center mt-3">
                <li className="list-inline-item mr-5">
                  <Link to="https://t.me/hydrachain" className="d-block text-white" target="_blank" rel="noreferrer">
                    <h2>
                      <i className="bx bxl-telegram"></i>
                    </h2>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://twitter.com/hydra_chain" className="d-block text-white" target="_blank" rel="noreferrer">
                    <h2>
                      <i className="bx bxl-twitter"></i>
                    </h2>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinCommunity;
